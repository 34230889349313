
.section__company {
  h4 {
    font-size: 3.2rem;
    font-weight: 700;
    margin-top: 7rem!important;
    &.no-margin {
      margin: -40px 0 20px!important;
    }
  }
  .content_18 {
    a {
      color: #ed1b2f;
      text-decoration: underline;
    }
  }
}
